import React from 'react'

export default function Home() {


  return (
    <>
      <div className="background-home-front" />
      <div className="background-home" />
    </>
  )
}