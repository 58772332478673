import React from 'react'
import MenuImage from '../menu.png'
import { Link } from "react-router-dom"

export const pages = [
  ['Breath Like Wine', 'breath-like-wine'],
  ['Don\'t Save Me', 'dont-save-me'],
  ['Drop That Gun', 'drop-that-gun'],
  ['Would You Tell Me', 'would-you-tell-me'],
  ['Something I Can Trust', 'something-i-can-trust'],
  ['Hummingbird', 'hummingbird']
]

const location = {
  'Breath Like Wine': {top: 55, left: 55},
  'Don\'t Save Me': {top: 100, left: 52},
  'Drop That Gun': {top: 150, left: 50},
  'Would You Tell Me': {top: 195, left: 50},
  'Something I Can Trust': {top: 245, left: 48},
  'Hummingbird': {top: 295, left: 45}
}

const width = 391
const height = 530

export default function Menu() {
  return (
    <div className='menu-wrapper'>
      <div className='menumap'>
        <img src={MenuImage} alt='' />
        <div className='menu-link-wrapper'>
          {pages.map(info => {
            const topDist = `${100. * location[info[0]].top / height}%`;
            const leftDist = `${100. * location[info[0]].left / width}%`;
            console.log(`top: ${location[info[0]].top}\tleft: ${location[info[0]].left}\ntop: ${topDist}\tleft: ${leftDist}`)
            return (
              <Link to={info[1]} className="image-link" style={{top: topDist, left: leftDist}}/>
            )
          })}
        </div>
      </div>
    </div>
  )
}