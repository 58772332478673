import React from 'react'
import './App.css';
import MusicPage from './components/MusicPage'
import Menu, { pages } from './components/Menu'
import Home from './components/Home'
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom"
import BreathImg from './breath-like-wine.png'
import DontSaveImg from './dont-save-me.png'
import DropThatImg from './drop-that-gun.png'
import WouldYouImg from './would-you-tell-me.png'
import SomethingImg from './something-i-can-trust.png'
import HummingbirdImg from './hummingbird.png'

import BreathSong from './audio/breath-like-wine.mp3'
import DontSaveSong from './audio/dont-save-me.mp3'
import DropThatSong from './audio/drop-that-gun.mp3'
import HummingbirdSong from './audio/hummingbird.mp3'
import SomethingISong from './audio/something-i-can-trust.mp3'
import WouldYouSong from './audio/would-you-tell-me.mp3'

export const images = {
  'breath-like-wine': BreathImg,
  'dont-save-me': DontSaveImg,
  'drop-that-gun': DropThatImg,
  'would-you-tell-me': WouldYouImg,
  'something-i-can-trust': SomethingImg,
  'hummingbird': HummingbirdImg
}

export const audios = {
  'breath-like-wine': BreathSong,
  'dont-save-me': DontSaveSong,
  'drop-that-gun': DropThatSong,
  'would-you-tell-me': WouldYouSong,
  'something-i-can-trust': SomethingISong,
  'hummingbird': HummingbirdSong
}



function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          {pages.map(([title, slug]) => (
            <Route exact path={`/${slug}`} element={<MusicPage title={title} src={images[`${slug}`]} slug={slug} audio={audios[`${slug}`]} /> } />
          ))}
          <Route exact path="/" element={<Home/>} />
        </Routes>
        <div className={`home-btn`}>
          <Link to='/'>home</Link>
        </div>
        <Menu />
        <div className='email-container'>
          <a href="mailto:graceyaconelli@gmail.com">graceyaconelli@gmail.com</a>
        </div>
      </Router>
    </div>
  );
}

export default App;
