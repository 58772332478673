import React, { useState, useEffect } from 'react'
import PauseBtnImg from '../pause-button.png'
import PlayBtnImg from '../play-button.png'
export default function MusicPage({
  title,
  src,
  slug,
  image,
  audio
}) {

  const [isPlaying, setIsPlaying ] = useState(false);
  
  useEffect(() => {
    setIsPlaying(false)
  }, [setIsPlaying, slug])

  return (
    <div className='full-screen-background-music'>
      <div className={`${slug} music-page-container`} style={{backgroundImage: `url(${src})`}}>
        <div className='title-container'>
        <h1 className='music-page-header'>{title}</h1>
        </div>
        <div className='audio-container'>
        <audio id={`${slug}-audio`}
          src={audio} type='audio/mpeg' preload='auto'>
            Your browser does not support the
            <code>audio</code> element.
        </audio>
        <div className='audio-player'>
        <button className='play-pause-btn' onClick={() => {
            isPlaying ? document.getElementById(`${slug}-audio`).pause() : document.getElementById(`${slug}-audio`).play();
            console.log('playing!! : ')
            console.log(document.getElementById(`${slug}-audio`))
            setIsPlaying(!isPlaying)
          }}>
        <img src={isPlaying ? PauseBtnImg : PlayBtnImg} alt={isPlaying ? 'Pause' : 'Play'} className={isPlaying ? 'paused' : ''} />
        </button>
          
        </div>
        </div>
      </div>
    </div>
  )
}